import { MegaphoneIcon } from '@heroicons/react/20/solid'

import { useParams } from 'react-router-dom';

import { Carousel } from 'flowbite-react';
import {useState} from "react";

const ContentSection = (props) => {
    const { id } = useParams();

    const [currentSlide, setCurrentSlide] = useState();

    function slideUser(index) {
        let user = "";
        switch (index) {
            case 0: case 1: {
                user = "Zauberwr";
                break;
            }
            case 2: case 3: {
                user = "NeverFall";
                break;
            }
            case 4: case 5: case 6: case 7: case 8: case 9: {
                user = "SirExpert_";
                break;
            }
            case 10: case 11: {
                user = "Till";
                break;
            }
            case 12: {
                user = "privateseconds";
                break;
            }
            case 13: {
                user = "Darknight";
                break;
            }
            default:
                break;
        }
        return user;
    }

    return (
        <div className="relative isolate overflow-hidden bg-transparent px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="lg:max-w-lg">
                            <p className="text-base font-semibold leading-7 text-indigo-600">12. Februar 2024</p>
                            <h1 className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">Minecraft Moshpit 2023 Map!</h1>
                        </div>
                    </div>
                </div>
                <div className="h-56 sm:h-64 xl:h-80 2xl:h-[34rem] -ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                    <Carousel slideInterval={5000} onSlideChange={(index) => setCurrentSlide(index)} className="shadow-xl">
                        <img src={`/images/Zauberwr_Tag.png`} alt="" />
                        <img src={`/images/ZauberwrNacht.png`} alt="" />
                        <img src={`/images/NeverFall_Leuchtturm.png`} alt="" />
                        <img src={`/images/NeverFall_Haus.png`} alt="" />
                        <img src={`/images/SirExpert_Freiheitsstatue_Rechts.png`} alt="" />
                        <img src={`/images/SirExpert_Freiheitsstatue_Front.png`} alt="" />
                        <img src={`/images/SirExpert_Stadion.png`} alt="" />
                        <img src={`/images/SirExpert_Yacht_Links.png`} alt="" />
                        <img src={`/images/SirExpert_yacht_Rechts.png`} alt="" />
                        <img src={`/images/SirExpert_Kirche.png`} alt="" />
                        <img src={`/images/Till_Haus.png`} alt="" />
                        <img src={`/images/Till_weg.png`} alt="" />
                        <img src={`/images/privatesecounds_Dorf.png`} alt="" />
                        <img src={`/images/Darknight_UnterwasserBase.png`} alt="" />
                    </Carousel>
                    <h3 className="relative float-right mt-3">von {slideUser(currentSlide)}</h3>
                </div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="max-w-xl text-base leading-7 lg:max-w-lg">
                            <p>
                                Wir freuen uns, bekannt zu geben, dass unsere Website ein Update erhalten hat! Ab sofort steht die Minecraft-Moshpit 2023 Map unter dem Reiter "Minecraft" zum Download bereit. 🗺️
                            </p>
                            <ul role="list" className="mt-8 space-y-8">
                                <li className="flex gap-x-3">
                                    <MegaphoneIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                    <strong className="font-semibold">🌞 Summer-Moshpit 2024! 🌊</strong> Seid ihr bereit für das ultimative Sommerevent? Summer-Moshpit 2024 findet im Mai statt. Freut euch auf ein spannendes Abenteuer und unvergessliche Momente im Summer-Moshpit 2024! 🎉
                  </span>
                                </li>
                            </ul>
                            <h2 className="mt-16 text-xl font-bold tracking-tight">~ Lennart | Orenka von TTV-Moshpit</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentSection;