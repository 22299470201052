import Streamer from "../components/Streamer";

const Partner = () => {

    return (
        <div className="flex justify-center items-center">
            <div className="card w-3/4 bg-base-300 shadow-xl mt-5 mb-5">
                <div className="card-body items-center text-center justify-around">
                    <div className="flex flex-col items-center gap-6">
                        <h1 className="font-title text-base-content text-3xl font-extrabold lg:text-4xl xl:text-6xl font-sans">Partner</h1>
                    </div>
                    <div class="grid gap-10 lg:gap-20 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
                        <Streamer name="Emeraldhost" rank="Partner" insta="emeraldhost.de" twitter="EmeraldHostDE" />
                        <Streamer name="JamieTVM" rank="Streamer" twitch="JamieTVM" insta="tvmjamie" twitter="JamieJamalo" />
                        <Streamer name="Acee47" rank="Streamer" twitch="acee47" insta="_acee47" twitter="ttvAcee47" />
                        <Streamer name="AmoAymen" rank="Streamer" twitch="AmoAymen" insta="amoaymenn" twitter="amoaymn" />
                        <Streamer name="BinAngi" rank="Streamerin" twitch="BinAngi" insta="binangelina" twitter="binangixd" />
                        <Streamer name="ElioLiveTv" rank="Streamer" twitch="ElioLiveTv" insta="elioyt_" twitter="elioooyt" />
                        <Streamer name="EsIstMarvin" rank="Streamer" twitch="EsIstMarvin" insta="esistmarvin" twitter="EsIstMarvin" />
                        <Streamer name="Firnunir" rank="Streamer" twitch="firnunir" insta="patrick_schober" twitter="Firnuir" />
                        <Streamer name="BlueGemi" rank="Streamer" twitch="BlueGemi" insta="BlueGemi" twitter="BlueGemi" />
                        <Streamer name="Geckarbre" rank="Streamer" twitch="Geckarbre" insta="geckarbre" twitter="Geckarbre" />
                        <Streamer name="HikaVT" rank="Streamer" twitch="HikaVT" insta="ttvhikavt" twitter="Hika_VT" />
                        <Streamer name="HuyTastic" rank="Streamer" twitch="HuyTastic" insta="huytastic" />
                        <Streamer name="J4nnisw" rank="Streamer" twitch="J4nnisw" insta="jannis.wrz" />
                        <Streamer name="JamienichtJames" rank="Streamer" twitch="JamienichtJames" insta="jamienichtjames" twitter="JamienichtJames" />
                        <Streamer name="Jonsman" rank="Streamer" twitch="Jonsman" insta="jonasmoche" twitter="thejonsman" />
                        <Streamer name="JustLucky" rank="Streamer" twitch="JustLucky" />
                        <Streamer name="Kanyuji" rank="Streamer" twitch="Kanyuji" insta="kanyujii" twitter="Kanyuji2" />
                        <Streamer name="Maaareq" rank="Streamer" twitch="maaareq" insta="maaareq" twitter="maaaaareq" />
                        <Streamer name="Minoooo_99" rank="Streamer" twitch="Minoooo_99" insta="minooo99_" />
                        <Streamer name="Muiliii" rank="Streamer" twitch="Muiliii" insta="muil.iii" />
                        <Streamer name="PlatinumDot" rank="Streamer" twitch="PlatinumDot" insta="platinumdotyt" twitter="PlatinumdotT" />
                        <Streamer name="Shaxreym" rank="Streamer" twitch="Shaxreym" twitter="Shaxreym" />
                        <Streamer name="SirExpert_" rank="Streamer" twitch="SirExpert_" insta="sirexpert" twitter="sirexpert_" />
                        <Streamer name="SpinceLive" rank="Streamer" twitch="SpinceLive" insta="spinceyt" twitter="Spincex" />
                        <Streamer name="Steini719" rank="Streamer" twitch="Steini719" insta="twitch.steini719" twitter="Steini719" />
                        <Streamer name="Stravex" rank="Streamer" twitch="Stravex" insta="stravex_" />
                        <Streamer name="SweetDenii" rank="Streamer" twitch="SweetDenii" insta="denii_music" twitter="deniiofficial" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Partner;