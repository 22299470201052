import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [userError, setUserError] = useState('')
    const [pwError, setPwError] = useState('')
    const [loginError, setLoginError] = useState('')

    let navigate = useNavigate();
    
    const onLogin = () => {

        if('' === username) {
            setUserError('Please enter your username')
        }

        if('' === password) {
            setPwError('Please enter your password')
            return
        }
        if(username === process.env.REACT_APP_ADMIN_USER && password === process.env.REACT_APP_ADMIN_PASSWORD) {
            navigate('../dashboard', {state: {user:username}})
            return;
        }
        setLoginError('Username or Password is wrong')
    }

    return (
        <div className="flex justify-center items-center">

            <div className="card w-full mt-5 mb-5">
                <div className="card-body items-center text-left justify-around">
                    <h2 className="card-title text-3xl mb-5">Moshpit Dashboard</h2>
                    <div className="mx-auto w-full max-w-2xl">
                        <div className="card my-10">
                            <div className="card-body flex flex-col gap-4 items-center">
                                <label className="form-control w-full max-w-xs">
                                    <div className="label">
                                        <span className="label-text">Username</span>
                                    </div>
                                    <input type="text" onChange={(event) => setUsername(event.target.value)} className="input input-bordered w-full max-w-xs" />
                                    <div className="label">
                                        <span className="label-text-alt text-red-400">{userError}</span>
                                    </div>
                                </label>

                                <label className="form-control w-full max-w-xs">
                                    <div className="label">
                                        <span className="label-text">Password</span>
                                    </div>
                                    <input type="password" onChange={(event) => setPassword(event.target.value)} className="input input-bordered w-full max-w-xs" />
                                    <div className="label">
                                        <span className="label-text-alt text-red-400">{pwError}</span>
                                    </div>
                                </label>

                                <button className="btn btn-accent" onClick={onLogin}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Login;