import NewsCard from "../components/NewsCard";
import { FaDiscord } from 'react-icons/fa';
import {Outlet, useParams} from "react-router-dom"

import summermoshpit from '../assets/summer_moshpit.png';

const openInNewTab = (url) => {
    window.open(url, '_blank', "noreferrer");
}

const NewsList = () => {
    const params = useParams();

    return (
        params.id == null ?
            <div className="flex justify-center items-center">

                <div className="card w-3/4 mt-5 mb-5">
                    <div className="card-body items-center text-left justify-around">
                        <div className="flex flex-col items-center gap-6">
                            <h1 className="font-title text-base-content text-3xl font-extrabold lg:text-4xl xl:text-6xl font-sans">News</h1>
                            <div className="divider"></div>
                        </div>
                        <div className="mx-auto w-full max-w-2xl">
                            <div className="grid justify-items-stretch gap-6">
                                <NewsCard title="Summer-Moshpit"
                                          content="🎉 Der Sommer ist endlich da und wir haben aufregende Neuigkeiten für euch! 🌞🎮 Am 31.05.24 geht Minecraft-Moshpit in eine Neue Runde: Das erste Mal als SUMMER MOSHPIT! 💥 Freut euch auf geisteskranken Content, coole Streamer und kreative Builds ! 🏝️"
                                          href="summer-moshpit-2024"
                                          image={summermoshpit}
                                />
                                <div className="divider"></div> 
                                <NewsCard title="Minecraft-Moshpit 2023 Map jetzt verfügbar!"
                                          content="Liebe Moshpit-Freunde, wir freuen uns, bekannt zu geben, dass unsere Website ein Update erhalten hat! Ab sofort steht die Minecraft-Moshpit 2023 Map unter dem Reiter Minecraft zum Download bereit. 🗺️"
                                          href="minecraft-moshpit-2023-map"
                                          image="https://ttv-moshpit.de/images/NeueWebsite-AktualisierungMinecraft-Moshpit2023Mapjetztverfgbar.png"
                                />
                            </div>

                            <div className="card bg-base-200 my-10">
                                <div className="card-body flex flex-col gap-4">
                                    <h2 className="text-xl font-black lg:text-4xl">Verpasse keine weiteren News!</h2>
                                    <div>
                                        <p className="font-bold">Trete unserem Discord bei, um immer Updates zu erhalten.</p>
                                        <p className="text-base-content/60 text-xs">Keine Sorge, du wirst nichts verpassen :^)</p>
                                    </div>
                                    <div>
                                        <div className="form-control w-full max-w-sm">
                                            <button role="link" onClick={() => openInNewTab('https://discordapp.com/invite/DpJc83tKh2')} className="join-item btn btn-accent"><FaDiscord />Join Discord</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
            : <Outlet />
    );
}
export default NewsList;
