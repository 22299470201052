import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { FaInstagram, FaDiscord, FaTwitter, FaYoutube, FaTeamspeak, FaPatreon } from 'react-icons/fa';

const Footer = () => {

    return (
        <footer className="footer p-10 bg-base-300 text-base-content">
            {/**
             <nav>
                <h6 className="footer-title">Services</h6>
                <a className="link link-hover">Support</a>
                <a className="link link-hover">Status</a>
            </nav>*/}
            <nav>
                <h6 className="footer-title">Rechtliches</h6>
                <Link to='/imprint' className="link link-hover">Impressum</Link>
                <Link to='/privacy' className="link link-hover">Datenschutzerklärung</Link>
                <Link to="https://www.it-recht-kanzlei.de/" target="_blank" className='w-[70%]'><img src="https://www.it-recht-kanzlei.de/logo/Logo_Juristisch_betreut_durch_IT-Recht_Kanzlei.png?i=524c9-38c4b-39c6-907c-1" alt="Vertreten durch die IT-Recht Kanzlei" /></Link>
            </nav>
            <nav>
                <h6 className="footer-title">Social</h6>
                <div className="grid grid-flow-col gap-4 transition-all">
                    <Link to='https://discordapp.com/invite/DpJc83tKh2' target='_blank' ><FaDiscord size={30} className="hover:text-[#7289da]" /></Link>
                    <Link to='https://twitter.com/TTVMoshpit' target='_blank'><FaTwitter size={30} className="hover:text-[#1DA1F2]"/></Link>
                    <Link to='https://youtube.com/@jamielul' target='_blank'><FaYoutube size={30} className="hover:text-[#FF0000]"/></Link>
                    <Link to='https://instagram.com/ttvmoshpit' target='_blank'><FaInstagram size={30} className="hover:text-[#C13584]"/></Link>
                    <Link to='ts3server://185.223.30.27?port=9987' target='_blank'><FaTeamspeak size={30} className="hover:text-[#2580c3]"/></Link>
                    <Link to='https://www.patreon.com/TTVMoshpit' target='_blank'><FaPatreon size={30} className="hover:text-[#F96854]"/></Link>
                </div>
            </nav>
        </footer>
    );

}

export default Footer;