import {FaTwitch, FaInstagram, FaTwitter, FaGlobe} from "react-icons/fa";
import { Link } from "react-router-dom";

const Streamer = (props) => {

    return (
        <div className="text-center">
              <img className="mx-auto mb-4 w-36 h-36 rounded-2xl object-cover" src={`/partner/${props.name}.png`} alt={props.name} />
              <h3 className="mb-1 text-2xl font-bold">
                  {props.name}
              </h3>
              <p>{props.rank}</p>
              <ul className="flex justify-center mt-4 space-x-4">
              {(props.rank === "Streamer" || props.rank === "Streamerin") ? <li><Link to={`https://twitch.tv/${props.twitch}`} target="_blank"><FaTwitch color="#6441a5" className="w-6 h-6" /></Link></li> : <li><Link to={`https://emeraldhost.de`} target="_blank"><FaGlobe className="w-6 h-6" /></Link></li>}
              {props.twitter !== undefined &&
                        <li>
                            <Link to={`https://twitter.com/${props.twitter}`} target="_blank"><FaTwitter color="#1DA1F2" className="w-6 h-6" /></Link>
                        </li>
                    }
                  {props.insta !== undefined &&
                        <li>
                            <Link to={`https://instagram.com/${props.insta}`} target="_blank"><FaInstagram color="#C13584" className="w-6 h-6" /></Link>
                        </li>
                    }
              </ul>
          </div>

    );
}

export default Streamer;