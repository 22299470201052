import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import HomePage from "./pages/HomePage";
import Team from "./pages/Team";
import Partner from "./pages/Partner";
import NewsList from "./pages/NewsList";
import ContentSection from "./components/ContentSection";
import Layout from "./components/Layout";
import Modlist from './pages/2024/Modlist';
import Impress from './pages/Impress';
import Privacy from './pages/Privacy';
import Dashboard from './pages/admin/Dashboard';
import Login from './pages/admin/login/Login';
import Downloads from './pages/Downloads';

const App = () => {
    return (
        <BrowserRouter>

            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path='team' element={<Team />} />
                    <Route path='partner' element={<Partner />} />
                    <Route path='news' element={<NewsList />}>
                        <Route path=':id' element={<ContentSection />}/>
                    </Route>
                    <Route path='downloads' element={<Downloads />}/>
                    <Route path='2024/modlist' element={<Modlist />}/>
                    <Route path='imprint' element={<Impress />}/>
                    <Route path='privacy' element={<Privacy />}/>
                </Route>
                <Route path='/admin'>
                    <Route index path='login' element={<Login />}/>
                    <Route path='dashboard' element={<Dashboard />}/>
                </Route>
                <Route path="*" element={<Navigate replace to="/" />}/>
            </Routes>

        </BrowserRouter>
    );
}

export default App;