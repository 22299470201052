import { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';

const Modlist = () => {

    const mods = [
        {
            name: "[Let's Do] API",
            description: "A Library for Forge and Fabric with utilities such as Render Features, Recipe Books and Wood API",
            redirect: "https://modrinth.com/mod/do-api",
            image: "https://cdn.modrinth.com/data/4XJZeZbM/01a4832b479fa718f6b68c10000e56078cb013a6.png"
        },
        {
            name: "[Let's Do] Brewery",
            description: "Ozapft is! Brau Bier, beiss in a Bretzn – willkomme zum Braufescht!",
            redirect: "https://modrinth.com/mod/lets-do-brewery",
            image: "https://cdn.modrinth.com/data/cV5LQXKx/47fffb1de0584f51e9710462d8a23ef279fea4ea.png"
        },
        {
            name: "[Let's Do] Vinery",
            description: "Let's do Wine!",
            redirect: "https://modrinth.com/mod/lets-do-vinery",
            image: "https://cdn.modrinth.com/data/1DWmBJVA/4aa492bb738ceb506ee1dd67f0ed862b164a165a.png"
        },
        {
            name: "AppleSkin",
            description: "Food/hunger-related HUD improvements",
            redirect: "https://modrinth.com/mod/appleskin",
            image: "https://cdn.modrinth.com/data/EsAfCjCV/icon.png"
        },
        {
            name: "Architectury API",
            description: "An intermediary api aimed to ease developing multiplatform mods.",
            redirect: "https://modrinth.com/mod/architectury-api",
            image: "https://cdn.modrinth.com/data/lhGA9TYQ/icon.png"
        },
        {
            name: "Balm",
            description: "Abstraction Layer (but not really)™ for Blay's multiplatform mods",
            redirect: "https://modrinth.com/mod/balm",
            image: "https://cdn.modrinth.com/data/MBAkmtvl/icon.png"
        },
        {
            name: "Biomes O' Plenty",
            description: "Adds 50+ unique biomes to enhance your world, with new trees, flowers, and more!",
            redirect: "https://modrinth.com/mod/biomes-o-plenty",
            image: "https://cdn.modrinth.com/data/HXF82T3G/ffb870e12c325b795d54833f8f899126553ef06f.png"
        },
        {
            name: "Botania",
            description: "An innovative natural magic themed tech mod",
            redirect: "https://modrinth.com/mod/botania",
            image: "https://cdn.modrinth.com/data/pfjLUfGv/4c006056fd05fdd63b83d79c7c3bb9712d2f49bc.png"
        },
        {
            name: "Clumps",
            description: "Clumps XP orbs together to reduce lag",
            redirect: "https://modrinth.com/mod/clumps",
            image: "https://cdn.modrinth.com/data/Wnxd13zP/e723aa383ad7161a541242097e1d3223f582b967.gif"
        },
        {
            name: "Curios API",
            description: "A flexible and expandable accessory/equipment API for users and developers.",
            redirect: "https://modrinth.com/mod/curios",
            image: "https://cdn.modrinth.com/data/vvuO3ImH/2a7323ca80849de0bcb50299e18acdf8bf394682.png"
        },
        {
            name: "Enhanced Boss Bars",
            description: "New improved boss bars!",
            redirect: "https://modrinth.com/resourcepack/enhanced-boss-bars",
            image: "https://cdn.modrinth.com/data/U5SedJ9S/25495e38b3d3d4ff285bee0baf3c033d26017568.png"
        },
        {
            name: "FallingTree",
            description: "Break down your trees by only cutting one piece of it",
            redirect: "https://modrinth.com/mod/fallingtree",
            image: "https://cdn.modrinth.com/data/Fb4jn8m6/icon.png"
        },
        {
            name: "Farmer's Delight",
            description: "A cozy expansion to farming and cooking!",
            redirect: "https://modrinth.com/mod/farmers-delight",
            image: "https://cdn.modrinth.com/data/R2OftAxM/8e7aa38ab94d94bb0a2894a218b69beb49002b34.png"
        },
        {
            name: "Icon Xaero's",
            description: "Icon mod for xaero's map and minimap Many icon mod",
            redirect: "https://modrinth.com/resourcepack/icon-xaeros",
            image: "https://cdn.modrinth.com/data/LWGq2eg6/5807286d3a18915b27038738b91750b6b2aaae21.jpeg"
        },
        {
            name: "Just Enough Items",
            description: "JEI - View Items and Recipes",
            redirect: "https://modrinth.com/mod/jei",
            image: "https://cdn.modrinth.com/data/u6dRKJwZ/565c44deeac2a38cbb6c600b5e002fac59572b88.png"
        },
        {
            name: "Just Enough Resources",
            description: "JEI integration that adds info on mobs, world gen, villagers and many more!",
            redirect: "https://modrinth.com/mod/just-enough-resources-jer",
            image: "https://cdn.modrinth.com/data/uEfK2CXF/db41e4e93f179df846380cbb2413f8120def75df.png"
        },
        {
            name: "Mekanism",
            description: "High-tech machinery, powerful energy generation, fancy gadgets and more.",
            redirect: "https://modrinth.com/mod/mekanism",
            image: "https://cdn.modrinth.com/data/OFVYKsAk/icon.png"
        },
        {
            name: "Mekanism Generators",
            description: "Advanced energy generation for Mekanism.",
            redirect: "https://modrinth.com/mod/mekanism-generators",
            image: "https://cdn.modrinth.com/data/OFVYKsAk/icon.png"
        },
        {
            name: "Mekanism Tools",
            description: "Powerful tools and armor for Mekanism.",
            redirect: "https://modrinth.com/mod/mekanism-tools",
            image: "https://cdn.modrinth.com/data/OFVYKsAk/icon.png"
        },
        {
            name: "MobLassos",
            description: "Gotta catch 'em all!",
            redirect: "https://modrinth.com/mod/mob-lassos",
            image: "https://cdn.modrinth.com/data/ftOBbnu8/363e5c74a83597f6a7fd9eb917072e65f8dcc75d.png"
        },
        {
            name: "Moonlight Lib",
            description: "dynamic data pack and registration, villager activities, custom map marker and a lot more",
            redirect: "https://modrinth.com/mod/moonlight",
            image: "https://cdn.modrinth.com/data/twkfQtEc/icon.png"
        },
        {
            name: "Nether's Delight",
            description: "A spicy expansion bringing the nether to Farmer's Delight!",
            redirect: "https://modrinth.com/mod/nethers-delight",
            image: "https://cdn.modrinth.com/data/Vv0RM7WN/bcc7b753ece2584ea956888971a98dc7f3045319.png"
        },
        {
            name: "Patchouli",
            description: "Accessible, Data-Driven, Dependency-Free Documentation for Minecraft Modders and Pack Makers",
            redirect: "https://modrinth.com/mod/patchouli",
            image: "https://cdn.modrinth.com/data/nU0bVIaL/130922464c30a3a61eb493ce71d2502b23f29905.png"
        },
        {
            name: "Puzzles Lib",
            description: "Why's it called Puzzles you ask? That's the puzzle!",
            redirect: "https://modrinth.com/mod/puzzles-lib",
            image: "https://cdn.modrinth.com/data/QAGBst4M/icon.png"
        },
        {
            name: "Simple Voice Chat",
            description: "A working voice chat in Minecraft!",
            redirect: "https://modrinth.com/plugin/simple-voice-chat",
            image: "https://cdn.modrinth.com/data/9eGKb6K1/icon.png"
        },
        {
            name: "Simply Backpacks",
            description: "",
            redirect: "https://www.curseforge.com/minecraft/mc-mods/simply-backpacks-forge",
            image: "https://media.forgecdn.net/avatars/881/257/638311338083829173.png"
        },
        {
            name: "TerraBlender",
            description: "A library mod for adding biomes in a simple and compatible manner!",
            redirect: "https://modrinth.com/mod/terrablender",
            image: "https://cdn.modrinth.com/data/kkmrDlKT/ffe690c604b123c63ee77a5baf76cf008cb73663.png"
        },
        {
            name: "Waystones",
            description: "Teleport back to activated waystones. For Survival, Adventure or Servers.",
            redirect: "https://modrinth.com/mod/waystones",
            image: "https://cdn.modrinth.com/data/LOpKHB2A/icon.png"
        },
        {
            name: "Xaero's Minimap",
            description: "Displays a map of the nearby world terrain, players, mobs, entities in the corner of your screen. Lets you create waypoints which help you find the locations you've marked.",
            redirect: "https://modrinth.com/mod/xaeros-minimap",
            image: "https://cdn.modrinth.com/data/1bokaNcj/80eb00784e250b99fb1789da35869387d14d5637.png"
        },
        {
            name: "Xaero's World Map",
            description: "Adds a full screen world map which shows you what you have explored in the world. Works great together with Xaero's Minimap.",
            redirect: "https://modrinth.com/mod/xaeros-world-map",
            image: "https://cdn.modrinth.com/data/1bokaNcj/80eb00784e250b99fb1789da35869387d14d5637.png"
        }
    ];

    const openInNewTab = (url) => {
        window.open(url, '_blank', "noreferrer");
    }


    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 6;
    const endOffset = itemOffset + itemsPerPage;
    const currentMods = mods.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(mods.length / itemsPerPage); 

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % mods.length;
        setItemOffset(newOffset);
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    function Mods({ currentMods }) {
        return (
            <>
                {currentMods && 
                    currentMods.map((mod, index) => (
                        <a key={index} href={mod.redirect} className="rounded-box hover:bg-base-200 group relative flex items-center justify-between overflow-hidden p-4 duration-300" target="_blank">
                            <img src={mod.image} alt={mod.name} className="w-14 mr-5 rounded-xl" />
                            <div className="flex-auto">
                                <h2 className="text-xl font-bold group-hover:underline">{mod.name}</h2>
                                <p className="text-base-content/60 text-sm font-bold">{mod.description}</p>
                            </div>
                            <svg className="inline-block h-5 w-5 opacity-0 duration-300 group-hover:opacity-50" width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 11H37V29" stroke="currentColor" strokeWidth="4" strokeLinecap="butt" strokeLinejoin="bevel"></path><path d="M11.5439 36.4559L36.9997 11" stroke="currentColor" strokeWidth="4" strokeLinecap="butt" strokeLinejoin="bevel"></path></svg>
                        </a>
                    ))
                }
            </>
        )
    }

    return (
        <div className="flex justify-center items-center">

            <div className="card w-full mt-5 mb-5">
                <div className="card-body items-center text-left justify-around">
                    <div className="flex flex-col items-center gap-6">
                        <h1 className="font-title text-base-content text-3xl font-extrabold lg:text-4xl xl:text-6xl font-sans">Summer-Moshpit Modliste</h1>
                        <div className="divider"></div>
                    </div>
                    <div className="mx-auto w-full max-w-3xl">
                        <div className="grid justify-items-stretch gap-6">

                            <Mods currentMods={currentMods} />
                            
                            
                        </div>
                        <ReactPaginate 
                                breakLabel="..."
                                nextLabel="»"
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                previousLabel="«"
                                renderOnZeroPageCount={null}
                                containerClassName='join float-right'
                                previousLinkClassName='join-item btn btn-sm m-1'
                                activeLinkClassName='btn-active'
                                pageLinkClassName='join-item btn btn-sm m-1'
                                nextLinkClassName='join-item btn btn-sm m-1'
                            />
                    </div>

                    <div className="mx-auto w-full max-w-2xl">
                        <div className="card my-10">
                            <div className="card-body flex flex-col gap-4 items-center">
                                <div className="form-control w-full max-w-sm">
                                    <button className="join-item btn btn-accent" role="link" onClick={() => openInNewTab("https://www.curseforge.com/minecraft/modpacks/ttv-moshpit-summer-moshpit-2024-official-modpack/download/5192198")}>Modpack runterladen<FaDownload /></button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default Modlist;