import { MegaphoneIcon } from '@heroicons/react/20/solid'
import MinecraftServer from '../components/MinecraftServer';

const HomePage = () => {
    return (
        <div>
            <MinecraftServer />

            <div className="relative isolate overflow-hidden bg-transparent px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <div className="lg:max-w-lg">
                                <p className="text-base font-semibold leading-7 text-indigo-600">23. April 2024</p>
                                <h1 className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">SUMMER MOSHPIT</h1>
                            </div>
                        </div>
                    </div>
                    <div className="h-56 sm:h-64 xl:h-80 2xl:h-[34rem] -ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                        <img src={`/images/summer_moshpit2.png`} alt="" className='shadow-xl rounded-xl' />
                    </div>
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <div className="max-w-xl text-base leading-7 lg:max-w-lg">
                                <p>
                                🎉 Der Sommer ist endlich da und wir haben aufregende Neuigkeiten für euch ! 🌞🎮
Am 31.05.24 geht Minecraft-Moshpit in eine Neue Runde: Das erste Mal als SUMMER MOSHPIT ! 💥
Freut euch auf geisteskranken Content, coole Streamer und kreative Builds ! 🏝️
                                </p>
                                <ul role="list" className="mt-8 space-y-8">
                                    <li className="flex gap-x-3">
                                        <MegaphoneIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                        <span>
                        <strong className="font-semibold">🌞 Summer-Moshpit 2024! 🌊</strong> Außerdem haben wir ab heute eine neue, aktuellere Website mit neuem Design. Checkt die unbedingt aus!
                    </span>
                                    </li>
                                </ul>
                                <h2 className="mt-16 text-xl font-bold tracking-tight">~ Lennart | Orenka von TTV-Moshpit</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;