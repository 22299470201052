import { useEffect, useState } from 'react';
import dirt_background from '../assets/minecraft_dirt_background.png';
import logo from '../assets/logo.png';
import ping from '../assets/ping.png';

const mcs = require('node-mcstatus');

const MinecraftServer = (props) => {

    const [online, setOnline] = useState(false);
    const [image, setImage] = useState('');
    const [motd, setMotd] = useState('');
    const [players, setPlayers] = useState(0);
    const [maxSlots, setMaxSlots] = useState(0);

    const host = 'TTV-Moshpit.de';
    const port = 25565;
    const options = { query: true };

    // The `port` argument is optional and defaults
    // to 25565. The `options` argument is optional.
    mcs.statusJava(host, port, options)
    .then((result) => {
        setImage(result.icon);
        setMotd(result.motd.html);
        setPlayers(result.players.online);
        setMaxSlots(result.players.max);
        setOnline(result.online);
    })
    .catch((error) => {
        console.error(error);
    })

    return (
        <div className="flex justify-center items-center">

            <div className="card w-2/5 bg-base-300 shadow-xl mt-5 mb-5" style={{backgroundImage: `url(${dirt_background})`}}>
                <div className="card-body flex-row items-center">
                    <div className="mr-[6px]">
                        <img src={(image == undefined ? logo : image)} alt="server logo" className='w-[100px]' />
                    </div>
                    <div className='flex-grow'>
                        {online ?
                        <>
                            <img src={ping} alt="ping" className='float-right ml-[5px]' />
                            <div className='mt-[1px] float-right outline-none font-minecraft text-[18px] text-gray tooltip tooltip-bottom'>{players}/{maxSlots}</div>                
                            <div className='mt-[1px] outline-none font-minecraft text-[18px] text-white'>{host}</div>
                            <div className='text-[18px] whitespace-pre-wrap font-minecraft' dangerouslySetInnerHTML={{__html: motd}}></div>
                        </>:
                        <>
                            <img src={ping} alt="ping" className='float-right ml-[5px]' />
                            <div className='mt-[1px] outline-none font-minecraft text-[18px] text-white'>{host}</div>
                            <div className='text-[18px] whitespace-pre-wrap font-minecraft text-[#aa0000]'>Can't connect to server</div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default MinecraftServer;