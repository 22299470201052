import logo from "../assets/logo.png";
import {FaDiscord, FaGithub, FaTwitter} from "react-icons/fa";

const TeamMember = (props) => {

    return (

        <div className="col-span-1 sm:col-span-1 lg:col-span-1">
            <div className="rounded-lg">
                <div className="p-4">
                    <div className="flex items-center">
                        <div className="w-16 h-16">
                            <img src={`https://minotar.net/helm/${props.name}`} alt={props.name}
                                 className="rounded-[20%]" style={{border: '1px solid rgba(0, 0, 0, .125)'}}/>
                        </div>
                        <div className="ml-3 text-left">
                            <h5 className="font-bold">{props.realname != undefined ? props.realname + " | " : ""}{props.name}</h5>
                            <h6 className="text-sm text-gray-500">{props.rank}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamMember;