import {Link} from "react-router-dom";

const NewsCard = (props) => {
    return (
        <Link to={props.href} className="card sm:card-side hover:bg-base-200 transition-colors sm:max-w-none">
            <figure className="mx-auto w-full object-cover p-6 max-sm:pb-0 sm:max-w-[12rem] sm:pe-0">
                {props.image == null ?
                    <div className="skeleton border-base-content bg-base-300 rounded-btn border border-opacity-5"/>
                    :
                    <img loading="lazy" src={props.image}
                    className="border-base-content rounded-btn"
                    alt=""/>

                }
            </figure>

            <div className="card-body text-left">
                <h2 className="card-title">{props.title}</h2>
                <p className="text-xs opacity-60">{props.content}</p>
            </div>
        </Link>
    )
}

export default NewsCard;