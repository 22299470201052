import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Dashboard = () => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(location.state === null) {
            navigate('../login')
            return;
        }
    })

    return (
        <div className="flex justify-center items-center">

            <div className="card w-full mt-5 mb-5">
                <div className="card-body items-center text-left justify-around">
                    <h2 className="card-title text-3xl mb-5">Moshpit Dashboard</h2>
                    <div className="mx-auto w-full max-w-2xl">
                        <div className="card my-10">
                            <div className="card-body flex flex-col gap-4 items-center">
                                {location.state !== null && <h2>Hello {location.state.user}</h2>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Dashboard;