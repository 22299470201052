import { FaPen } from 'react-icons/fa';
import TeamMember from "../components/TeamMember";

const openInNewTab = (url) => {
    window.open(url, '_blank', "noreferrer");
}

const Team = () => {

    return (
        <div className="flex justify-center items-center">
            <div className="card w-3/4 mt-5 mb-5 mx-auto">
                <div className="card-body rounded-xl bg-base-300 shadow-xl text-center">
                    <div className="flex flex-col items-center gap-6">
                        <h1 className="font-title text-base-content text-3xl font-extrabold lg:text-4xl xl:text-6xl font-sans">Team</h1>
                    </div>
                    <div className="divider">
                        <div className="badge rounded-md badge-lg badge-error font-bold">Administration</div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
                        <TeamMember realname="Lennart B." name="veqou" rank="Administrator"/>
                        <TeamMember realname="Jamie K." name="JamieTVM" rank="Administrator"/>
                        <TeamMember realname="Lennart E." name="Orenka" rank="Administrator"/>
                    </div>

                    <div className="divider">
                        <div className="badge rounded-md badge-lg badge-info font-bold">Developer</div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
                        <TeamMember realname="Emil" name="threeseconds" rank="Senior Developer"/>
                        <TeamMember name="Spigotexception" rank="Developer"/>
                    </div>

                    <div className="divider">
                        <div className="badge rounded-md badge-lg badge-error font-bold">Moderator</div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
                        <TeamMember realname="Julian" name="privateseconds" rank="Senior Moderator"/>
                        <TeamMember name="blueberrq" rank="Moderator"/>
                        <TeamMember name="LordMarcDerEchte" rank="Moderator"/>
                        <TeamMember name="Benetastic" rank="Moderator"/>
                        <TeamMember name="Strelizia" rank="Moderator"/>
                        <TeamMember name="tyshox" rank="Moderator"/>
                    </div>

                    <div className="divider">
                        <div className="badge rounded-md badge-lg badge-secondary font-bold">Content</div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
                        <TeamMember name="Mochi" rank="Content"/>

                    </div>

                    {/* BUILDER
                    
                    <div className="divider">
                        <div className="badge rounded-md badge-lg badge-warning font-bold">Builder</div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
                        

                    </div>

                    */}

                </div>

                <div className="mx-auto w-full max-w-2xl">
                    <div className="card bg-base-200 my-10">
                        <div className="card-body flex flex-col gap-4">
                            <h2 className="text-xl font-black lg:text-4xl">Du möchtest auch hier stehen?</h2>
                            <div>
                                <p className="font-bold">Trete unserem Discord bei und bewirb dich!</p>
                                <p className="text-base-content/60 text-xs">#📚・bewerbung</p>
                            </div>
                            <div>
                                <div className="form-control w-full max-w-sm">
                                    <button role='link' onClick={() => openInNewTab('https://discord.com/channels/1038080203076931664/1114582711831642317')} className="join-item btn btn-accent"><FaPen/>Bewerbung erstellen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Team;