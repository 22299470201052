import { FaDownload } from "react-icons/fa";

import background from '../assets/summer_moshpit_map_background.png';

const Downloads = () => {
    return (
        <div className="w-full px-4 pt-20 md:px-20" dir="ltr">
            <div className="flex flex-col items-center gap-6">
                <h1 className="font-title text-base-content text-3xl font-extrabold lg:text-4xl xl:text-6xl font-sans">Downloads</h1>
                <div className="divider"></div>
            </div>
            <div>
                <div className="flex flex-col items-center gap-6"></div>
                <div className="mx-auto flex max-w-7xl flex-col gap-16">
                    <div className="rounded-box relative grid grid-cols-12 gap-y-10 py-10 xl:gap-x-10">
                        <div className="col-span-12 row-start-2 flex flex-col gap-8 xl:col-span-5 xl:row-start-1">
                            <div>
                                <h2 className="font-title text-lg font-black [text-wrap:balance] font-sans sm:text-3xl xl:text-5xl">Summer-Moshpit
                                    2024 Map</h2>
                            </div>
                            <div className="flex items-start justify-between">
                                <div className="flex flex-col items-center gap-3">
                                    <a href="http://185.223.30.27/download/Summer-Moshpit2024_Map.zip"
                                       className="btn btn-success shadow-success/50 group shrink-0 rounded-xl shadow xl:px-10"
                                       target="_blank" rel="noopener noreferrer">Download <FaDownload/></a>
                                </div>
                            </div>
                            <div
                                className="prose prose-sm prose-li:my-0 prose-ul:leading-none prose-li:leading-normal prose-p:my-2 prose-ul:my-2 text-xs [text-wrap:balance]">
                                <p>Die Summer-Moshpit Map aus dem Jahr 2024</p>
                            </div>
                        </div>

                        <div className="col-span-12 row-start-1 flex flex-col gap-6 xl:col-span-7">
                            <div
                                className="rounded-box border-base-200 group relative block overflow-hidden border object-cover">
                                <div
                                    className="absolute inset-0 z-[1] grid place-content-center bg-black/50 opacity-0 transition-all duration-500 group-hover:scale-150 group-hover:opacity-100">
                                    <svg className="text-white" width="32" height="32" viewBox="0 0 48 48" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21 38C30.3888 38 38 30.3888 38 21C38 11.6112 30.3888 4 21 4C11.6112 4 4 11.6112 4 21C4 30.3888 11.6112 38 21 38Z"
                                            fill="none" stroke="currentColor" stroke-width="4"
                                            stroke-linejoin="round"></path>
                                        <path d="M21 15L21 27" stroke="currentColor" stroke-width="4"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M15.0156 21.0156L27 21" stroke="currentColor" stroke-width="4"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M33.2216 33.2217L41.7069 41.707" stroke="currentColor" stroke-width="4"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </div>
                                <img src={background} alt="background" loading="lazy" />

                            </div>
                        </div>
                    </div>

                    <div className="rounded-box relative grid grid-cols-12 gap-y-10 py-10 xl:gap-x-10">
                        <div className="col-span-12 row-start-2 flex flex-col gap-8 xl:col-span-5 xl:row-start-1">
                            <div>
                                <h2 className="font-title text-lg font-black [text-wrap:balance] font-sans sm:text-3xl xl:text-5xl">Moshpit
                                    2023 Map</h2>
                            </div>
                            <div className="flex items-start justify-between">
                                <div className="flex flex-col items-center gap-3">
                                    <a href="http://185.223.30.27/download/Minecraft-Moshpit2023_Map.zip"
                                       className="btn btn-success shadow-success/50 group shrink-0 rounded-xl shadow xl:px-10"
                                       target="_blank" rel="noopener noreferrer">Download <FaDownload/></a>
                                </div>
                            </div>
                            <div
                                className="prose prose-sm prose-li:my-0 prose-ul:leading-none prose-li:leading-normal prose-p:my-2 prose-ul:my-2 text-xs [text-wrap:balance]">
                                <p>Die Moshpit-Map aus dem Jahr 2023</p>
                            </div>
                        </div>

                        <div className="col-span-12 row-start-1 flex flex-col gap-6 xl:col-span-7">
                            <div
                                className="rounded-box border-base-200 group relative block aspect-[16/9] overflow-hidden border object-cover">
                                <div
                                    className="absolute inset-0 z-[1] grid place-content-center bg-black/50 opacity-0 transition-all duration-500 group-hover:scale-150 group-hover:opacity-100">
                                    <svg className="text-white" width="32" height="32" viewBox="0 0 48 48" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21 38C30.3888 38 38 30.3888 38 21C38 11.6112 30.3888 4 21 4C11.6112 4 4 11.6112 4 21C4 30.3888 11.6112 38 21 38Z"
                                            fill="none" stroke="currentColor" stroke-width="4"
                                            stroke-linejoin="round"></path>
                                        <path d="M21 15L21 27" stroke="currentColor" stroke-width="4"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M15.0156 21.0156L27 21" stroke="currentColor" stroke-width="4"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M33.2216 33.2217L41.7069 41.707" stroke="currentColor" stroke-width="4"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </div>
                                <div className="skeleton w-full h-full"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Downloads;
